import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import "./index.css";
import { Routes, Route } from "react-router-dom";
import LandingPage from "./components/pages/LandingPage";
import StawiNavbar from "./components/common/StawiNavbar";
import AboutUsPage from "./components/pages/AboutUsPage";
import ProductsPage from "./components/pages/ProductsPage";
import ContactUsPage from "./components/pages/ContactUsPage";
import Sustainability from "./components/pages/Sustainability";
import Footer from "./components/common/Footer";
import { FaWhatsapp } from "react-icons/fa";
import { BiPhone } from "react-icons/bi";
import ScrollToTop from "./components/event/ScrollToTop";

function App() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("374205804");
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  let stickyButtonStyle =
    "transition delay-100 hover:-translate-x-3 ease-out hover:scale-100 duration-700 fixed cursor-pointer z-30 p-2 items-center hover:w-[80px] inline-flex text-stawi-white place-content-center";

  return (
    <>
    <ScrollToTop />
      <StawiNavbar />
      <a
        href="https://wa.me/+254763466910?text= Stawi Customer"
        data-action="share/whatsapp/share"
        target="_blank"
        rel="noreferrer"
        className={`${stickyButtonStyle} mt-10 bg-stawi-green lg:top-14 md:top-16 top-8`}
      >
        <FaWhatsapp size={22} />
      </a>
      <a
        href="tel:+254 763 466910"
        className={`${stickyButtonStyle} mt-20 lg:top-14 md:top-16 top-7 bg-greenblue`}
      >
        <BiPhone size={22} />
      </a>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route path="/aboutus" element={<AboutUsPage />} />
        <Route path="/brands" element={<ProductsPage />} />
        <Route path="/contactus" element={<ContactUsPage />} />
        <Route path="/partners" element={<Sustainability />} />
        <Route path="*" element={<LandingPage />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
