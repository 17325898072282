import React, { useState } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import StawiButton from "../common/StawiButton";
import StawiIcon from "../common/StawiIcon";
import { StawiCards } from "../common/StawiCards";
import { BiAccessibility, BiPlus, BiSearch } from "react-icons/bi";
import { AiFillCheckCircle, AiOutlineContacts } from "react-icons/ai";
import { FaThumbsUp, FaWhatsapp } from "react-icons/fa";
import { BsWhatsapp } from "react-icons/bs";
import { RiHandHeartFill, RiPlantFill } from "react-icons/ri";
import { ContactUsForm } from "./ContactUsPage";
import { Link } from "react-router-dom";

function HomeHeroSection() {
  return (
    <div className="w-full lg:h-screen h-[60%] bg-none relative lg:top-8 top-4">
      <AliceCarousel
        duration={700}
        autoPlay={true}
        startIndex={0}
        fadeOutAnimation={`${true}`}
        mouseDragEnabled={false}
        playButtonEnabled={true}
        autoPlayInterval={2000}
        keyboardNavigation={true}
        disableButtonsControls={`${true}`}
        infinite={true}
      >
        <div className="w-full h-screen ">
          <img
            src="qualityfoods.jpg"
            className="w-full h-full object-cover object-center absolute"
          />
          <div className="bg-stawi-black/60 w-full h-full absolute items-center grid place-content-center lg:px-0 md:px-[60px]">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-16 lg:px-6 lg:w-[794px] h-fit grid place-items-center">
              <h1 className="mb-4 lg:text-[55px] md:text-[35px] text-[35px] tracking-tight capitalize text-stawi-white font-poppins  leading-tight">
                Give Your Family the Nutrients They Need to Excel
              </h1>
              <h3 className="mb-6 font-poppins md:text-lg text-[16px] text-stawi-white lg:px-[60px] mg:px-[60px] text-center">
                Stawi foods and fruits Offers Great Options for Different Food
                Products. Call Stawi foods and fruits to Inquire about Our Food
                Products!
              </h3>
              <a href="/brands" className="">
                <StawiButton
                  buttonText={"OUR BRANDS"}
                  buttonStyle={
                    "hover:bg-stawi-green bg-stawi-darkgreen rounded text-stawi-white"
                  }
                />
              </a>
            </div>
          </div>g
        </div>

        <div className="w-full h-screen">
          <img
            src="sus.jpg"
            className="w-full h-full object-cover object-center absolute"
          />
          <div className="bg-stawi-black/60 w-full h-full absolute items-center grid place-content-center lg:px-0 md:px-[60px]">
            <div className="py-8 px-4 mx-auto max-w-screen-xl text-center sm:py-16 lg:px-6 lg:w-[794px] h-fit grid place-items-center">
              <h1 className="mb-4 lg:text-[55px] md:text-[35px] text-[35px] tracking-tight capitalize text-stawi-white font-poppins  leading-tight">
                Kenyan-based CPG company providing nutritious, accessible food.
              </h1>
              <h3 className="mb-6 font-poppins md:text-lg text-[16px] text-stawi-white lg:px-[60px] mg:px-[60px] text-center">
                At our core, we are dedicated to offering remarkable food
                products that prioritize variety, nutrient density,
                affordability, and accessibility. Our commitment drives us to
                continuously improve and innovate, ensuring an exceptional
                experience for our valued consumers.
              </h3>
              <div className="flex ">
                <a href="/aboutus" className="mr-4">
                  <StawiButton
                    buttonText={"LEARN MORE"}
                    buttonStyle={
                      "bg-stawi-white rounded text-stawi-darkgreen hover:bg-stawi-darkgreen hover:text-stawi-white"
                    }
                  />
                </a>
                <a href="/contactus" className="">
                  <StawiButton
                    buttonText={"CONNECT"}
                    buttonStyle={
                      "bg-stawi-darkgreen text-stawi-white hover:bg-stawi-green rounded"
                    }
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </AliceCarousel>
    </div>
  );
}

export const BudgeCardComponent = ({ icon, title, description }) => {
  return (
    <div className="lg:w-[350px] md:w-[350px] shadow grid place-items-center lg:h-fit p-2 hover:shadow-lg">
      <StawiIcon
        icon={icon}
        borderPresent
        borderStyle={"rounded-full p-10 border-none"}
        iconStyle={"stawi-darkgreen bg-stawi-green lg:w-[150px]"}
      />
      <h1 className="text-[18px] mt-4 uppercase mb-4 text-stawi-darkgreen font-poppins">
        {title}
      </h1>
      <p className="text-stawi-black font-poppins">{description}</p>
    </div>
  );
};

export const BudgeSection = () => {
  const budgeCards = [
    {
      icon: <RiPlantFill size={70} />,
      title: "Nutritious and Fortified Food Products:",
      description:
        "We offer a range of pre-cooked and fortified flour mixes, such as Stawi Junior and Stawi Family. These products are carefully formulated to provide essential nutrients for infants, children, and the whole family. Our flour mixes are rich in protein,carbohydrates, vitamins, and minerals, ensuring that our consumers have access to balanced and healthy food options.",
    },
    {
      icon: <RiHandHeartFill size={70} />,
      title: "Value-added Agricultural Processing:",
      description:
        "Being a social agro-processing enterprise, Stawi Foods actively engages in value-added agricultural processing. We mill cereals and dry fruits, utilizing ingredients such as maize, millet, soya, wheat, and amaranth, to create our range of flour mixes. Through this process, we enhance the value of these agricultural commodities, resulting in convenient and ready-to-use products that cater to the diverse nutritional needs of our consumers.",
    },
    {
      icon: <BiAccessibility size={70} />,
      title: "Accessibility and Affordability:",
      description:
        "We are committed to ensuring that our products are accessible and affordable to a wide range of consumers. Our nutritious food options are available in major and mini-supermarkets, as well as retail outlets serving low-income consumers. By doing so, we ensure that our products reach a broad customer base. We also prioritize affordability to make our products accessible to individuals from various socioeconomic backgrounds. Our goal is to promote inclusivity and contribute to food security in the communities we serve.",
    },
  ];

  return (
    <div className="h-fit lg:h-full w-full items-center justify-center lg:py-[100px]  py-[50px] lg:px-[100px] md:px-[30px] px-4">
      <div className=" lg:flex justify-between md:grid md:grid-cols-2 lg:gap-0 gap-y-12 place-content-center grid-cols-1 w-full h-full pt-16">
        {budgeCards.map((budgeCard) => (
          <div key={Math.random(12 ** 4)}>
            <BudgeCardComponent
            title={budgeCard.title}
            description={budgeCard.description}
            icon={budgeCard.icon}
          />
          </div>
          
        ))}
      </div>
    </div>
  );
};

export const ProductSection = ({ productspage }) => {
  return (
    <div
      className={`${
        productspage
          ? "bg-none w-screen "
          : "bg-gradient-to-b from-stawi-green from-50% lg:h-fit lg:py-10 py-4 to-stawi-darkgreen to-50% px-0 "
      } lg:px-[80px]`}
    >
      <h1 className="text-center text-[30px] font-bold mb-10 z-10 text-stawi-white uppercase">
        Our Brands
      </h1>
      <div className="w-full h-fit justify-between lg:flex lg:px-0 md:px-[60px] px-4">
        <div className="text-white w-full md:h-[500px] h-[300px] block lg:hidden">
          <img
            src="33.jpg"
            className="w-full h-full rounded object-center object-cover"
            alt="Stawi Family Porridge"
          />
        </div>
        <div className="text-white text-left lg:w-[50%] pt-[60px] lg:pr-10 ">
          <h1
            className={` ${
              productspage ? "text-stawi-darkgreen" : " text-stawi-white "
            } text-[25px] mb-10 font-bold`}
          >
            Stawi Nurture Family Porridge
          </h1>
          <p
            className={`${
              productspage
                ? " text-stawi-green"
                : "mb-10 text-stawi-white font-poppins capitalize"
            }`}
          >
            Consumed as a hot beverage, Stawi Nurture Family Porridge is a
            delicious product that is carefully formulated to cater to the
            nutritional requirements of the whole family regardless of age or
            gender.
          </p>
          <Link to={"/brands"} className="">
            <StawiButton
              buttonText={"Explore Products"}
              buttonStyle={`${
                productspage ? "invisible" : "visible"
              } w-fit lg:mb-0 mb-4 bg-stawi-white text-stawi-darkgreen hover:bg-stawi-darkgreen hover:text-stawi-white`}
            />
          </Link>
        </div>
        <div className="text-white h-fit lg:block hidden  lg:w-[50%]">
          <img
            src="33.jpg"
            className="lg:w-full w-full lg:h-[500px] rounded object-center object-cover"
            alt="Happy Family Porridge"
          />
        </div>
      </div>

      <div className=" w-full h-fit justify-between lg:mt-2 mt-[100px] pb-4 lg:flex lg:px-0 md:px-[60px] px-4">
        <div className="text-white lg:w-[50%] md:h-[500px] h-[300px]">
          <img
            src="32.jpg"
            className="lg:w-full w-full lg:h-[500px] md:h-[500px] h-[300px] rounded object-center object-cover"
            alt="Stawi Junior Prorridge"
          />
        </div>
        <div className="text-white text-left lg:w-[50%] pt-[60px] lg:pl-10">
          <h1
            className={` ${
              productspage ? "text-stawi-darkgreen" : " text-stawi-white "
            } text-[25px] mb-10 font-bold lg:mt-0 `}
          >
            Stawi Junior Porridge
          </h1>
          <p
            className={`${
              productspage
                ? " text-stawi-green"
                : "mb-10 text-stawi-white font-poppins capitalize"
            }`}
          >
            Give your little ones the good stuff to help them explore new
            textures. Stawi Junior is a deliciously tasty and softly chewable
            porridge with a mix of four grains with a nutritional profile that
            is carefully matched to meet your child's Recommended Daily
            Allowance (RDA). It is the perfect choice for parents during and
            after weaning as it contains all nutrients needed during early
            growth and development.
          </p>
          <Link to={"/brands"}>
            <StawiButton
              buttonText={"Explore Products"}
              buttonStyle={`${
                productspage ? "invisible" : "visible"
              } w-fit lg:mb-0 mb-4 bg-stawi-green text-stawi-white hover:bg-stawi-white hover:text-stawi-darkgreen`}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export const AboutUsSection = () => {
  return (
    <div className="h-fit w-full gap-6 mt-12 pt-6 lg:px-[100px] md:px-[60px] px-0 ">
      <div className="lg:flex">
        <div className="text-stawi-darkgreen text-left font-light lg:w-[50%] lg:mx-0 mx-4">
          <h1 className="text-[30px] font-bold mb-12 text-stawi-darkgreen">
            Know Who We Are
          </h1>
          <div className="lg:w-[45%] block lg:hidden lg:px-0 px-4 ">
            <img
              src="hero.jpg"
              className="lg:ml-24 rounded-lg lg:h-[500px] lg:mt-0 mt-4"
              alt="Stawi Pure Porridge"
            />
          </div>
          <p className="font-poppins text-justify text-stawi-black mb-10">
            Stawi Foods and Fruits Limited is a Kenyan-based consumer packaged
            goods company specializing in providing nutritious and great tasting
            composite porridge flour blends to Kenyan families. Our products are
            precooked, convenient, safe and affordable.
          </p>
          <ul className="max-w-md space-y-1 my-12 text-[16px] text-stawi-darkgreen font-poppins">
            <li className="flex items-center gap-4">
              <AiFillCheckCircle />
              Quality and Nutritious Food Accessibility and Affordability
            </li>
            <li className="flex items-center gap-4">
              <AiFillCheckCircle />
              Commitment to Sustainability and Community Support
            </li>
            <li className="flex items-center gap-4">
              <AiFillCheckCircle />
              Remarkable Food Products
            </li>
          </ul>

          <Link to={"/aboutus"}>
            <StawiButton
              buttonText={"Learn more"}
              buttonStyle={
                "w-[200px] bg-stawi-darkgreen text-stawi-white hover:bg-stawi-green"
              }
            />
          </Link>
        </div>
        <div className="lg:w-[45%] lg:block hidden lg:px-0 px-4 ">
          <img
            src="hero.jpg"
            className="lg:ml-24 rounded-lg lg:h-[500px] lg:mt-0 mt-4"
            alt="Stawi Pure Porridge"
          />
        </div>
      </div>
    </div>
  );
};

export const TestimonialSection = () => {
  return (
    <div className="h-fit w-full lg:gap-6 mt-12 pt-6 mb-24 lg:px-[100px] px-4 ">
      <h1 className="text-center lg:py-12 mb-16 text-[30px] font-bold text-stawi-darkgreen">
        What our customers say about us
      </h1>

      <div className="grid lg:grid-cols-3 gap-x-6 lg:gap-x-12">
        <StawiCards
          testimonialCard={true}
          image={
            "https://media.licdn.com/dms/image/C4D03AQFVsb0t3x5xDA/profile-displayphoto-shrink_200_200/0/1655929532286?e=1687392000&v=beta&t=xQvjpI9ycxZZpHyOmJDuRGMXbFk-lzvOIfy3TD2xQGw"
          }
          title={"Software developer"}
          description={
            "Stawi Foods and Fruits Limited is a Kenyan-based consumer packaged goods company specializing in providing nutritious and great tasting composite porridge flour blends to Kenyan families. Our products are precooked, convenient, safe and affordable."
          }
          name={"Andrew Mbugua"}
        />
        <StawiCards
          testimonialCard={true}
          image={
            "https://media.licdn.com/dms/image/C4D03AQFVsb0t3x5xDA/profile-displayphoto-shrink_200_200/0/1655929532286?e=1687392000&v=beta&t=xQvjpI9ycxZZpHyOmJDuRGMXbFk-lzvOIfy3TD2xQGw"
          }
          title={"Software developer"}
          description={
            "Stawi Foods and Fruits Limited is a Kenyan-based consumer packaged goods company specializing in providing nutritious and great tasting composite porridge flour blends to Kenyan families. Our products are precooked, convenient, safe and affordable."
          }
          name={"Andrew Mbugua"}
        />
        <StawiCards
          testimonialCard={true}
          image={
            "https://media.licdn.com/dms/image/C4D03AQFVsb0t3x5xDA/profile-displayphoto-shrink_200_200/0/1655929532286?e=1687392000&v=beta&t=xQvjpI9ycxZZpHyOmJDuRGMXbFk-lzvOIfy3TD2xQGw"
          }
          title={"Software developer"}
          description={
            "Stawi Foods and Fruits Limited is a Kenyan-based consumer packaged goods company specializing in providing nutritious and great tasting composite porridge flour blends to Kenyan families. Our products are precooked, convenient, safe and affordable."
          }
          name={"Andrew Mbugua"}
        />
      </div>
    </div>
  );
};

export const FAQs = () => {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  return (
    <div className="2xl:container mt-[80px] 2xl:mx-auto lg:py-12 lg:px-6 md:px-[60px] py-9 mb-4 px-4">
      <h2 className="font-poppins lg:text-4xl mb-12 text-3xl lg:leading-7 leading-9 text-stawi-darkgreen">
        Frequently Asked Questions
      </h2>
      <div className="mt-4 flex lg:justify-between lg:items-start lg:flex-row flex-col justify-start items-start">
        <p className=" font-poppins text-[18px] text-base leading-6 text-stawi-green lg:w-9/12 ">
          Here are few of the most frequently asked questions by our valueable
          customers
        </p>
      </div>
      <div className=" flex lg:flex-row flex-col lg:space-x-8 lg:mt-16 mt-8">
        <div className=" lg:w-5/12  w-full ">
          <img
            src="32.jpg"
            className="w-full lg:block hidden"
            alt="Stawi porridge"
          />
        </div>
        <div className=" lg:w-8/12 w-full lg:mt-0 sm:mt-14 mt-10">
          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className=" font-poppins text-[20px] leading-5 text-stawi-darkgreen">
                What kind of suppliers does Stawi Foods work with?
              </h3>
              <button
                aria-label="too"
                className="cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stawi-darkgreen"
                onClick={() => setShow(!show)}
              >
                <BiPlus />
              </button>
            </div>
            <p
              className={
                "font-poppins text-[18px] text-green text-base leading-6  mt-4 w-11/12 " +
                (show ? "block" : "hidden")
              }
            >
              Stawi Foods works with suppliers who practice sustainable farming
              and biodiversity.
            </p>
          </div>
          <hr className=" my-7 bg-gray-200" />
          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className="font-poppins text-[20px] leading-5 text-stawi-darkgreen">
                How does Stawi Foods prioritize the quality and accessibility of
                its products?
              </h3>
              <button
                aria-label="too"
                className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stawi-darkgreen"
                onClick={() => setShow2(!show2)}
              >
                <BiPlus />
              </button>
            </div>
            <p
              className={
                "font-poppins text-[18px] text-green text-base leading-6 mt-4 w-11/12 " +
                (show2 ? "block" : "hidden")
              }
            >
              Stawi Foods prioritizes improving the variety, nutrient density,
              affordability, and accessibility of their products to put people
              first.
            </p>
          </div>
          <hr className=" my-7 bg-gray-200" />
          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className="font-poppins text-[20px] leading-5 text-stawi-darkgreen">
                How does Stawi Foods support rural farming communities?
              </h3>
              <button
                aria-label="too"
                className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stawi-darkgreen"
                onClick={() => setShow3(!show3)}
              >
                <BiPlus />
              </button>
            </div>
            <p
              className={
                "font-poppins text-[18px] text-green text-base leading-6 mt-4 w-11/12 " +
                (show3 ? "block" : "hidden")
              }
            >
              Stawi Foods' suppliers work with rural farming communities to
              responsibly produce products while providing support and education
              to strengthen long-term relationships.
            </p>
          </div>
          <hr className=" my-7 bg-gray-200" />
          <div>
            <div className=" flex justify-between items-center cursor-pointer">
              <h3 className="font-poppins text-[20px] leading-5 text-stawi-darkgreen">
                What are the key factors that differentiate Stawi Foods from its
                competitors in the market?
              </h3>
              <button
                aria-label="too"
                className=" cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-stawi-darkgreen"
                onClick={() => setShow4(!show4)}
              >
                <BiPlus />
              </button>
            </div>
            <p
              className={
                "font-poppins text-[18px] text-green text-base leading-6 mt-4 w-11/12 " +
                (show4 ? "blcok" : "hidden")
              }
            >
              Stawi Foods differentiates itself from competitors in the flour
              milling industry through its unique product offerings of
              pre-cooked and fortified flour mixes for infants, children, and
              families, as well as a gluten-free banana flour, emphasizing
              higher nutritional content and the use of natural ingredients
              without preservatives. With a focus on urban consumers, Stawi
              Foods also contributes to social impact by supporting smallholder
              farmers, creating jobs, and promoting value addition in the
              agricultural sector.
            </p>
          </div>

          <hr className=" my-7 bg-gray-200" />
        </div>
      </div>
    </div>
  );
};

export const PartnersSection = () => {
  const responsive = {
    0: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1024: {
      items: 6,
    },
  };

  const style = "rounded-lg h-[100px] w-full px-4 grid place-items-center";

  const items = [
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTBljMnxB-6jU8j81OThsP0seb8XWSgO5cjpw&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQdfMbFGQ9maS9JrBHnmXI4iTU52m2OGWiawQ&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTxs4q0V2gQL3qSYOxki_6Ts6TC3QdG3NXtA&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMOZe5pDPZzWJDHTH2myIRorV9vlA1_OEKuQ&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR5mtr5Ba_Ys5f-nfMivXulKiKY-NifV1FhFA&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRXEXLTkdRAIymQzj_zFU6vX7FPIP_s2IwLng&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSW7oltqaTbhmolNC4FiCYqWQtu3Y0OCxmhoA&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNOxfksqYXfhQjgB7dPWtR9zRZL4vnGGfOireKwf-aLvfBDkMOnrWz4WK7WhLVP7gkZ-c&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcShzCgeykMV4iymgYjJ_dcFcizsOkdLeHbtrg&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRoiIX6f0dW7kEh_m9uYHAk-y5Ic4oGywYFyw&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCELn_wesfPTZweyTo2T1If0fu6q6rUTWL4Q&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSvd6KoXt-z_3zxCVInqqOGhVvxLdMr2HHBOQ&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQVn6jJTLUjaEy_EQsRM1US4dQScs9dfNbd-A&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR34dpYJIMoeJxOiz1huLWVdJjPOh0ACCWvtg&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkSnUcLbP_2rrGIumtmkHLXX3-W4DBGgwAOw&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ3iEeUl1OuNaG-IDnS-uKmXpiPhS0V0tOt3w&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqsVAqDcBnm21FMrskTYqncYQwum6Fpq8xIg&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRilYL4vRC3jpDH5hbCFgr_PJIeLdOKqIfUbQ&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSBNhzfNKmCyrV17TwLx78_3pdgtco7d9S1uw&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSCrI1h2ftEVWc_fJRMjI3RhTKURc0GOy7LTw&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQa4H9Qj8eZ6TeAk-hZkM7QNWFthi8OA8xrLA&usqp=CAU"
      className={style}
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCMLj_NPiLNz7EZFAFkJrnX7mF-JA-kfNcbA&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQhD_om2gEkegWAk7QrgUATLQJpTw93p26pew&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img
      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnRJO9_IumXXxBliqF7coytH1oPhQQqwC7tg&usqp=CAU"
      className={style}
      alt="Stawi partner"
    />,
    <img src="open.jpeg" alt="Stawi partner" className={style} />,
    <img src="entury.jpeg" alt="Stawi partner" className={style} />,
  ];

  return (
    <div className="lg:h-fit mb-[100px] h-auto lg:mx-0 mx-4">
      <h1 className="lg:text-left text-center py-[60px] text-[30px] font-bold text-stawi-darkgreen">
        Our partners
      </h1>
      <div className="w-full justify-evenly">
        <AliceCarousel
          duration={400}
          autoPlay={true}
          startIndex={0}
          fadeOutAnimation={`${true}`}
          mouseDragEnabled={false}
          playButtonEnabled={true}
          responsive={responsive}
          autoPlayInterval={2000}
          keyboardNavigation={true}
          disableButtonsControls={`${true}`}
          items={items}
          infinite={true}
        />
      </div>
    </div>
  );
};

export const MissionVision = ({ aboutus }) => {
  return (
    <div className="lg:flex h-fit w-full lg:gap-6 lg:px-0 md:px-[60px] px-2 border-t-stawi-white border-t pt-[100px] pb-8">
      <div className="text-stawi-white block text-center font-light lg:w-[50%]">
        <h1
          className={`${
            aboutus ? "text-stawi-darkgreen text-left" : "text-stawi-darkgreen"
          } text-[30px] font-bold mb-8 `}
        >
          Our Vision
        </h1>
        <p
          className={` font-poppins text-[16px] ${
            !aboutus
              ? "text-stawi-black text-center"
              : "text-stawi-black leading-loose text-center"
          }`}
        >
          Our vision is to be a leading provider of nutritious processed foods
          in Kenya and other African countries and a meaningful direct and
          indirect employer in Kenya. Stawi’s seeks to be the leading provider
          of nutritious foods that meet changing consumer needs while creating
          value for local communities through sustainable sourcing practices.
        </p>
      </div>

      <div className="text-stawi-white block lg:mt-0 mt-6 text-center font-light lg:w-[50%]">
        <h1
          className={`${
            aboutus ? "text-stawi-darkgreen text-left" : "text-stawi-darkgreen"
          } text-[30px] font-bold mb-8 `}
        >
          Our mission
        </h1>
        <p
          className={`pb-4 font-poppins text-[16px] ${
            aboutus
              ? "text-stawi-black leading-loose text-center"
              : " text-stawi-black text-center"
          }`}
        >
          Stawi’s mission is to produce highly nutritious affordable products
          using locally sourced inputs, and available in ready to eat or easy to
          prepare forms. Stawi is committed to healthy, tasty, and quality
          products targeting children and family breakfast kind of meals. To
          achieve consistency in quality, taste and nutritional value of the
          products Stawi has adopted the following core values: Direct sourcing
          of high quality raw materials directly from farmers to maintain
          quality control of its products. Precooking the raw materials to give
          the end products a superior taste and flavour that set it apart from
          other competing products Utilizing proprietary processing methods that
          aim to retain the highest nutritional value even after processing and
          cooking such as soon to be introduced Hydrothermal Processing at Stawi
          factory
        </p>
      </div>
    </div>
  );
};

export const LastSection = () => {
  return (
    <div className=" lg:py-10 py-6 bg-stawi-green bg-opacity-20 lg:px-[100px] px-0 lg: block">
      <PartnersSection />
      <MissionVision />
      <div className="bg-stawi-white w-full h-full ">
        <FAQs />
      </div>
    </div>
  );
};

export const ChatSection = () => {
  return (
    <div className="w-full lg:h-[400px] h-auto bg-gradient-to-b grid place-content-center from-stawi-darkgreen from-50% to-stawi-green lg:pb-0 lg:px-[100px] px-4 md:px-[50px]">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h2 className="mb-4 text-4xl tracking-tight capitalize font-extrabold leading-tight">
            we would love to hear from you
          </h2>
          <p className="mb-6 font-poppins text-stawi-white text-[18px] ">
            Reach out to use via whatsapp or email and our team will respond
          </p>
        </div>

        <div className="flex mt-12 w-full lg:gap-6 md:gap-6 gap-2 justify-center">
          <a
            href="https://wa.me/+254763466910?text= Stawi Customer"
            data-action="share/whatsapp/share"
            target="_blank"
            rel="noreferrer"
          >
            <StawiButton
              buttonText={"WHATSAPP"}
              icon={true}
              buttonIcon={<BsWhatsapp size={25} />}
              iconStyle={"lg:px-3 md:px-3 px-1"}
              buttonStyle={
                "bg-stawi-green rounded-full text-stawi-white hover:bg-stawi-darkgreen"
              }
            />
          </a>
          <a href="/contactus" className="">
            <StawiButton
              buttonText={"CONTACT"}
              icon={true}
              buttonIcon={<AiOutlineContacts size={25} />}
              iconStyle={"lg:px-3 md:px-3 px-1"}
              buttonStyle={
                "bg-stawi-darkgreen text-stawi-white hover:bg-stawi-green rounded-full"
              }
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default function LandingPage() {
  return (
    <div className="block py-[60px] max-w-[1920px]">
      <HomeHeroSection />
      <BudgeSection />
      <ProductSection />
      <AboutUsSection />
      <div className="my-[70px]" />
      <LastSection />
      <ChatSection />
    </div>
  );
}
