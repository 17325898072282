import * as React from "react";
import { FaQuoteLeft } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";

export function RenderStarRating() {
  return (
    <>
      {[...Array(5)].map((star) => {
        return (
          <BsStarFill
            key={Math.random(100)}
            className="cursor-pointer"
            size={20}
          />
        );
      })}
    </>
  );
}

export const StawiCards = ({
  homePageCard,
  homePageCardStyle,
  productCard,
  testimonialCard,
  children,
  title,
  name,
  team,
  price,
  description,
  image,
}) => {
  return (
    <div>
      {homePageCard && (
        <div
          className={`rounded border border-stawi-green w-[250.5px] h-fit relative${
            homePageCardStyle ? homePageCardStyle : ""
          }`}
        >
          <img
            src={image}
            className={"w-[100%] rounded"}
            alt={"Stawi Foods and Fruits Limited"}
          />
          <div
            className={`w-[100%] h-[35%] grid text-md font-bold place-items-center text-stawi-white px-1 bg-stawi-darkgreen bg-opacity-50 absolute bottom-0 left-0 right-0`}
          >
            <h3>{title}</h3>
          </div>
        </div>
      )}

      {testimonialCard && (
        <div className="mb-12 md:mb-0">
          <div className="flex justify-center mb-6">
            <img
              src={image}
              alt="Stawi customers"
              className="rounded-full shadow-lg w-32"
            />
          </div>
          <h5 className="text-lg font-bold text-center mb-4">{name}</h5>
          <h6 className="font-medium text-stawi-darkgreen text-center mb-4">
            {title}
          </h6>
          <div className="mb-4 text-center">
            <div className="w-6 pr-2 inline-block text-center text-stawi-green">
              <FaQuoteLeft />
            </div>
            <span className="text-font-light text-sm">{description}</span>
          </div>
          <div className="flex place-content-center text-stawi-green">
            <RenderStarRating />
          </div>
        </div>
      )}
      {productCard && (
        <div className="group relative shadow p-1 rounded hover:shadow-lg">
          <div className="min-h-80 aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-t lg:aspect-none lg:h-[400px] md:h-[300px]">
            <img
              src={image}
              alt={"stawi products"}
              className="h-full w-full object-cover object-center lg:h-full lg:w-full"
            />
          </div>
          <div className="p-4 flex justify-between">
            <div>
              <h1 className="text-[18px] capitalize text-stawi-darkgreen font-bold">
                {title}
              </h1>
              <p className="mt-1 font-poppins text-stawi-black">{description}</p>
            </div>
          </div>
          <div className="w-full h-fit mt-2 p-4 block gap-y-4">{children}</div>
          <p className="text-sm text-stawi-darkgreen mt-1 font-bold absolute right-0">
            {price}
          </p>
        </div>
      )}
      {team && (
        <div class="w-full px-4 md:w-1/2 xl:w-1/4">
          <div class="mx-auto mb-10 w-full max-w-[370px]">
            <div class="relative overflow-hidden rounded-lg">
              <img
                src="https://cdn.tailgrids.com/1.0/assets/images/team/team-01/image-02.jpg"
                alt="stawi team"
                class="w-full"
              />
              <div class="absolute bottom-5 left-0 w-full text-center">
                <div class="relative mx-5 overflow-hidden rounded-lg bg-white py-5 px-3">
                  <h3 class="text-dark text-base font-semibold">{name}</h3>
                  <p class="text-body-color text-sm">{name}</p>
                  <div>
                    <span class="absolute left-0 bottom-0"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
