import React from "react";

export default function StawiButton({
  buttonType,
  buttonStyle,
  handleSubmit,
  buttonText,
  buttonIcon,
  icon,
  iconStyle,
}) {
  return (
    <button
      onSubmit={handleSubmit}
      type={buttonType}
      className={`h-[50px] shadow  items-center flex place-content-center px-6 py-1 font-poppins text-[16px] ${
        buttonStyle ? buttonStyle : ""
      } `}
    >
      {buttonText}
      {icon && (
        <span className={`${iconStyle ? iconStyle : ""}`}>
          {buttonIcon ? buttonIcon : ""}
        </span>
      )}
    </button>
  );
}
