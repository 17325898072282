import React, { useRef } from "react";
import emailjs from "emailjs-com";
import StawiButton from "../common/StawiButton";
import { BiArrowToRight, BiMailSend, BiPhoneCall } from "react-icons/bi";
import { ChatSection } from "./LandingPage";
import StawiInput from "../common/StawiInput";
import StawiTextarea from "../common/StawiTextarea";
import swal from "@sweetalert/with-react";

export const ContactUsForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_p8goxlx",
        "template_8runex7",
        form.current,
        "ziGy_Plhtoa8TOaIo"
      )
      .then(
        (result) => {
          console.log(result.text);

          swal(
            <div>
              <h1 className="text-[23px] font-serif text-stawi-darkgreen mt-[12px]">
                Hello!
              </h1>
              <p>Mail sent successfully, You'll receive an email shortly</p>
            </div>
          );
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="w-full h-fit lg:py-12 px-4 lg:my-0 my-10 mx-auto max-w-screen-md">
      <h2 className="mx-8 text-[30px] tracking-tight font-extrabold text-center text-stawi-darkgreen">
        CONTACT US
      </h2>
      <form onSubmit={sendEmail} ref={form} className="space-y-4">
        <StawiInput
          name={"name"}
          placeholder={"Name"}
          type={"text"}
          id={"name"}
        />
        <StawiInput
          name={"email"}
          placeholder={"Email"}
          type={"text"}
          id={"email"}
        />
        <StawiInput
          name={"subject"}
          placeholder={"Subject"}
          type={"text"}
          id={"subject"}
        />
        <StawiTextarea />
        <StawiButton
          buttonText={"Send Message"}
          handleSubmit={sendEmail}
          buttonStyle={"item-center bg-stawi-darkgreen text-stawi-white hover:bg-stawi-green"}
          iconStyle={"ml-1"}
          icon={true}
          buttonIcon={<BiArrowToRight size={30} />}
        />
      </form>
    </div>
  );
};

export default function ContactUsPage() {
  return (
    <>
      <div className="w-full sm:py-0 lg:px-[140px] bg-gradient-to-t lg:static lg:mb-[200px] from-stawi-green from-50%  md:h-fit md:py-0 py-4 to-stawi-darkgreen to-50% px-0 lg:flex">
        <div className="w-full grid lg:grid-cols-3 grid-cols-1 gap-1 h-fit lg:mt-[150px] lg:mb-[-150px]">
          <div className=" col-span-2 lg:mt-0 mt-11 bg-stawi-white shadow-sm shadow-stawi-black px-10">
            <ContactUsForm />
          </div>
          <div className=" bg-stawi-white shadow py-6 shadow-stawi-black">
            <h1 className="text-[30px] text-center text-stawi-darkgreen font-bold">
              CONNECT
            </h1>
            <div className="text-stawi-green space-y-4 font-light p-6 text-md grid place-content-center">
              <div className="grow-0 shrink-0 basis-auto w-full lg:w-7/12">
                <div className="flex flex-wrap">
                  <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="p-4 bg-stawi-black rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                          <BiPhoneCall size={60} />
                        </div>
                      </div>
                      <div className="grow ml-6">
                        <p className="font-bold mb-1 ">Call</p>
                        <p className="">0763466910</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grow-0 shrink-0 basis-auto w-full lg:w-7/12">
                <div className="flex flex-wrap">
                  <div className="mb-12 grow-0 shrink-0 basis-auto w-full lg:w-6/12 px-3 lg:px-6">
                    <div className="flex items-start">
                      <div className="shrink-0">
                        <div className="p-4 bg-stawi-black rounded-md shadow-md w-14 h-14 flex items-center justify-center">
                          <BiMailSend size={60} />
                        </div>
                      </div>
                      <div className="grow ml-6">
                        <p className="font-bold mb-1">email</p>
                        <p className="">stawifoods@gmail.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ChatSection />
    </>
  );
}
