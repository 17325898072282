import React from "react";
import { MissionVision } from "./LandingPage";
import { ContactUsForm } from "./ContactUsPage";

export default function AboutUsPage() {
  return (
    <>
      <div className="w-full lg:px-[10%]  px-6 py-[105px] bg-stawi-green bg-opacity-20">
        <div className="bg-stawi-green rounded-t h-[400px] relative">
          <img
            className="max-w-full w-full h-full absolute object-cover rounded-t"
            src="qualityfoods.jpg"
            alt="Family Porridge"
          />
          <div className="w-full h-full absolute bg-stawi-black/40 grid place-content-center rounded-t">
            <h1 className="lg:text-[40px] capitalize mb-4 tracking-tight lg:text-start text-center text-[30px] text-stawi-white font-poppins">
              Our History
            </h1>
          </div>
        </div>
        <div>
          <h1 className="mb-[70px] mt-8 text-[18px] font-poppins text-left text-stawi-darkgreen ">
            Stawi Foods is a Kenyan-based consumer packaged goods company
            specializing in providing remarkable food products to consumers.
            Since our founding in 2012, we are on a mission of making
            high-quality and nutritious food accessible to more people, while
            elevating our communities and making the world a healthier place.
            We’re committed to putting people first by improving the variety,
            nutrient density, affordability and accessibility of our products.
            We bring together remarkable people, bonded over our love of food —
            people who are hungry for what’s next. We work with suppliers who
            practice sustainable farming and biodiversity. Our suppliers work
            with rural farming communities to responsibly produce products while
            providing support and education to strengthen long term
            relationships.
          </h1>
          <MissionVision aboutus={false} />
        </div>
        <ContactUsForm />
      </div>
    </>
  );
}
