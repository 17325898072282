import React from "react";
import { BiPhone, BiPhoneCall } from "react-icons/bi";
import {
  FaFacebook,
  FaLinkedin,
  FaTwitter,
} from "react-icons/fa";
import { FiMail } from "react-icons/fi";

export default function Footer() {

  let contactStyle = "mb-4 transition ease-in delay-100 hover:-translate-y-1 hover:scale-100 duration-300 flex cursor-pointer oldstyle-nums hover:underline items-center justify-center md:justify-start"

  let iconStyle = "text-stawi-white p-2 transition ease-in delay-100 hover:-translate-y-1 hover:scale-100 duration-300"

  return (

    <footer className="bg-stawi-white max-w-[1920px] font-poppins text-center text-stawi-darkgreen lg:text-left">
      <div className="flex items-center justify-center border-b-2 border-stawi-green p-6 lg:justify-between">
        <div className="mr-12 hidden lg:block">
          <span className="font-bold text-[18px]">Get connected with us on social networks:</span>
        </div>
        <div className="flex gap-1 justify-center">
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/Stawifoods/"  className={ `${iconStyle} bg-blue `}>
            <FaFacebook />
          </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/StawiFoods" className={`${iconStyle} bg-paleblue `}>
            <FaTwitter />
          </a>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/stawi-foods-and-fruits-limited/about/"  className={`${iconStyle} bg-greenblue `}>
            <FaLinkedin />
          </a>
        </div>
      </div>

      <div className="mx-6 py-10 text-center md:text-left">
        <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
          <div className="">
            <h6 className="mb-4 flex items-center justify-center uppercase md:justify-start">
              STAWI FOODS AND FRUITS LIMITED
            </h6>
            <p className="text-stawi-darkgreen">
              A food processing company that makes convenient nutritious foods
              using sustainably sourced raw materials.
            </p>
          </div>

          <div className="">
            <h6 className="mb-4 flex justify-center uppercase md:justify-start">
              Products
            </h6>
            <p className="mb-4">
              <a href="products" className="text-stawi-green hover:underline">
              Stawi Nurture Family Porridge
              </a>
            </p>
            <p className="mb-4">
              <a href="products" className="text-stawi-green hover:underline">
                Stawi Junior Porridge
              </a>
            </p>
          </div>

          <div className="">
            <h6 className="mb-4 flex justify-center uppercase md:justify-start">
              Quick links
            </h6>
            <p className="mb-4">
              <a href="aboutus" className="text-stawi-green hover:underline">
                About us
              </a>
            </p>
            <p className="mb-4">
              <a href="products" className="text-stawi-green hover:underline">
                Our Brands
              </a>
            </p>
            <p className="mb-4">
              <a href="partners" className="text-stawi-green hover:underline">
              Sustainability
              </a>
            </p>
            <p>
              <a href="contactus" className="text-stawi-green hover:underline">
              Connect
              </a>
            </p>
          </div>

          <div>
            <h6 className="mb-4 flex justify-center uppercase md:justify-start">
              Contact
            </h6>
            <a href="mailto:stawifoods@gmail.com?subject = Feedback&body = Message" className={contactStyle}>
              <FiMail size={22} />
              <span className="ml-3">stawifoods@gmail.com</span>
            </a>
            <a href="tel:+254763466910" className={contactStyle}>
              <BiPhoneCall size={22} />
              <span className="ml-3">+254 763 466910</span>
            </a>
          </div>
        </div>
      </div>

      <div className="p-6 text-center">
        <span>© 2023 Copyright: </span>
        <a
          className="capitalize text-stawi-darkgreen"
          href="/"
        >
          Stawi foods and fruits limited
        </a>
      </div>
    </footer>
  );
}
