import React from "react";
import { StawiCards } from "../common/StawiCards";
import { MdCancel } from "react-icons/md";
import { AiFillCheckCircle } from "react-icons/ai";

export default function ProductsPage() {
  const familyList = [
    "11 Vitamins",
    "5 Minerals",
    "High fiber",
    "Calcium",
    "Easy cook porridge",
  ];

  const juniorFamily = [
    "11 Vitamins",
    "5 Minerals",
    "High fiber",
    "Calcium",
    "Easy cook porridge ",
  ];

  return (
    <div className="bg-stawi-white pt-[35px] mb-24 lg:mb-10 ">
      <div className="mx-auto max-w-2xl px-4 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8">
        <h1 className="lg:text-[40px] capitalize tracking-tight lg:text-start text-center text-[30px] text-stawi-darkgreen font-poppins">
          Our Brands
        </h1>
        <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
          <StawiCards
            productCard={true}
            image={"Stawi Family.jpeg"}
            title={"Nurture Family Porridge"}
            description={
              "Consumed as a hot beverage, Stawi Nurture Family Porridge is a delicious product that is carefully formulated to cater to the nutritional requirements of the whole family regardless of age or gender. "
            }
            children={
              <ul className="mb-8 space-y-4 text-left text-gray-500 dark:text-gray-400">
                {familyList.map((list) => (
                  <li
                    key={Math.random(12 ** 2)}
                    className="flex text-sm font-poppins uppercase text-stawi-green items-center space-x-3"
                  >
                    <AiFillCheckCircle size={20} />
                    <span> {list} </span>
                  </li>
                ))}

                <li className="flex text-sm font-poppins uppercase text-[#ff5c5c] items-center space-x-3">
                  <MdCancel size={20} />
                  <span> Added Sugar </span>
                </li>
              </ul>
            }
          />
          <StawiCards
            productCard={true}
            image={"Stawi Junior.jpeg"}
            title={"Stawi Junior Porridge"}
            description={
              "Give your little ones the good stuff to help them explore new textures. Stawi Junior is a deliciously tasty and softly chewable porridge with a mix of four grains with a nutritional profile that is carefully matched to meet your child's Recommended Daily Allowance (RDA). It is the perfect choice for parents during and after weaning as it contains all nutrients needed during early growth and development."
            }
            children={
              <ul className="mb-8 space-y-4 text-left text-gray-500 dark:text-gray-400">
                {juniorFamily.map((list) => (
                  <li
                    key={Math.random(12 ** 1)}
                    className="flex text-sm uppercase font-poppins text-stawi-green items-center space-x-3"
                  >
                    <AiFillCheckCircle size={20} />
                    <span> {list} </span>
                  </li>
                ))}
                <li className="flex text-sm uppercase font-poppins text-[#ff5c5c] items-center space-x-3">
                  <MdCancel size={20} />
                  <span>Added Sugar </span>
                </li>
              </ul>
            }
          />
        </div>
      </div>
    </div>
  );
}
