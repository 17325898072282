import React from 'react'

export default function StawiTextarea() {
  return (
    <div className="sm:col-span-2">
          <textarea
            id="message"
            name="message"
            rows="6"
            className="block p-2.5 outline-none w-full text-sm text-stawi-darkgreen border border-stawi-green rounded-[4px]"
            placeholder="Leave a message..."
          ></textarea>
        </div>
  )
}
