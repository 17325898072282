import React from "react";

function StawiInput({ label, type,id, name, placeholder }) {
  return (
    <div className="relative">
     
      <input
        type={type}
        id={id}
        name={name}
        className="peer block min-h-[auto]  border border-stawi-green outline-none text-stawi-darkgreen placeholder:stroke-stawi-green text-sm rounded-[4px] focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none transition-all duration-200 ease-linear peer-focus:text-primary w-full p-2.5"
        placeholder={placeholder}
        required
      />

         <label className="block mb-1 text-sm font-inter text-stawi-darkgreen">
        {label}
      </label>
    </div>
  );
}

export default StawiInput;
