import React from "react";

export default function Sustainability() {
  return (
    <div className="w-full py-[100px] overflow-x-hidden  h-fit lg:px-[20%] md:px-[60px] relative px-4 mx-auto grid place-items-center">
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTeb1KF51mryzhZprEiLZC6nUrwdNxo6SNjxQ&usqp=CAU"
        className="lg:w-[250px] w-[80px] object-cover lg:h-[250px] z-10 h-auto absolute left-0 top-24"
        alt="Porridge soghum"
      />
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTbKBE7ajUxFQ6I2y0c7yCpsLgB-_Fa-URBw&usqp=CAU"
        className="lg:w-[250px] w-[80px] object-cover lg:h-[250px] z-10 h-auto absolute left-0 lg:bottom-44 bottom-[500px]"
        alt="Porridge soghum"
      />
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTqfV2MJmGq2jUF0RxfrhVuL2odk7EUGGM1kQ&usqp=CAU"
        className="lg:w-[250px] w-[80px] object-cover lg:h-[250px] z-10 h-auto absolute right-0 lg:bottom-24 bottom-0"
        alt="Porridge soghum"
      />
      <img
        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTfTEp4SjwdypbHHc-AaCPHOrQgPG7x3r25Rg&usqp=CAU"
        className="lg:w-[250px] w-[80px] object-cover lg:h-[250px] z-10 h-auto absolute right-0 top-[300px] rotate-90"
        alt="Porridge soghum"
      />
      <img
        className="h-auto z-20 max-w-full rounded-t"
        src="sus.jpg"
        alt="Family Porridge"
      />
      <h1 className="text-[26px] font-poppins my-4 text-stawi-darkgreen text-left w-full">
        Sustainability (Food made right):
      </h1>
      <h3 className="mt-2 first-letter:text-[50px] z-20 text-[16px] text-left font-poppins text-stawi-black ">
        The food supply chain emits a third of all gases that contribute to
        climate change, finding a way to combat this defines Stawi’s
        environmental pillar. Stawi Foods Tree Planting Initiative works with
        partners to introduce farmers to nitrogen-fixing plants to maintain the
        permanent fertility of agricultural fields in Kenya. The initiative is a
        combination of preventing slash and burn farming and tree planting as a
        very efficient way of offsetting CO2 emissions.
      </h3>
      <h1 className="text-[26px] font-poppins my-4 text-stawi-darkgreen text-left w-full">
        Packaging
      </h1>
      <h3 className="mt-2 text-[16px] font-poppins text-left text-stawi-black ">
        We’re reducing the environmental impact of packaging by increasing use
        of plastic-free materials and innovating to make our packaging better
        for the planet.
      </h3>
      <h1 className="text-[26px] font-poppins z-20 my-4 text-stawi-darkgreen text-left w-full">
        Sourcing
      </h1>
      <div className=" lg:inline-flex inline-block">
        <img
          className="h-auto lg:w-[50%] w-full lg:m-6 m-0 max-w-full rounded-lg"
          src="source.jpg"
          alt=" porridge"
        />

        <div>
          <figcaption className="mt-2 text-[16px] font-poppins text-center text-stawi-black ">
            We believe in smallholder arable farmers and fairness in the supply
            chain. We source our key ingredients including sorghum and millet
            from smallholder farmers. We were an early adopter of responsible
            sourcing and continue to develop partnerships with suppliers who
            practice sustainable farming and biodiversity. Our suppliers work
            with rural farming communities to responsibly produce products while
            providing support and education to strengthen long term
            relationships. We’re partnering with farmers in key regions where we
            source ingredients to advance regenerative agriculture in Kenya. We
            also take a broad approach to addressing food loss and waste in
            Kenya, which contribute to both environmental and economic
            challenges.
          </figcaption>
        </div>
      </div>

      <h1 className="text-[24px] 6-20 font-poppins my-4 text-stawi-darkgreen text-left w-full">
        Social
      </h1>
      <figcaption className="mt-2 text-[16px] font-poppins text-left z-20 text-stawi-black ">
        The social pillar of Sustainability is understanding, appreciating and
        valuing both our employees and the community in which the business is
        based. We have an internal human rights policy to reflect our commitment
        to looking after our employees and workers in the supply chain. We have
        mandatory procedures in place to make sure basic human rights are
        respected. These include: working conditions are safe and hygienic,
        child labour shall not be used, living wages are paid, working hours are
        not excessive, no discrimination is practised, and no harsh or inhumane
        treatment is allowed. Everyday, hundreds of consumers people eat food
        from our factory – This is a very privileged and intimate trust that
        customers have with Stawi. We continuoulsy review the safety, hygiene,
        quality and ethics of our production processes
      </figcaption>
    </div>
  );
}
