import React, { useState } from "react";

import { BiMailSend, BiMenu, BiPhoneCall, BiX } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import StawiButton from "./StawiButton";

const renderButtons = (smallscreen) => {
  return (
    <div
      className={`lg:flex md:flex lg:mr-0 ${
        smallscreen ? "visible" : "hidden"
      } hidden lg:visible md:visible mr-4 lg:text-[14px] text-[10px] items-center justify-center`}
    >
      <a href="tel:+254763466910" className="ml-2 ">
        <StawiButton
          icon
          buttonIcon={<BiPhoneCall size={24} />}
          buttonStyle={
            "bg-stawi-white hover:shadow-none shadow-lg text-stawi-darkgreen rounded-[4px]"
          }
          buttonText={"Call us"}
          iconStyle={"px-2"}
        />
      </a>

      <a
        href="mailto:stawifoods@gmail.com?subject = Feedback&body = Message"
        className="ml-2"
      >
        <StawiButton
          icon
          buttonIcon={<BiMailSend size={24} />}
          buttonText={"Email"}
          buttonStyle={
            "rounded-[4px] text-stawi-white bg-stawi-darkgreen hover:shadow-none shadow-lg"
          }
          iconStyle={"px-2"}
        />
      </a>
    </div>
  );
};

const StawiNavbar = (smallscreen) => {
  const links = [
    {
      to: "/",
      label: "Home",
    },
    {
      to: "brands",
      label: "Our brands",
    },
    {
      to: "aboutus",
      label: "About us",
    },
    {
      to: "partners",
      label: "Sustainability",
    },
    {
      to: "contactus",
      label: "Connect",
    },
  ];

  const [open, setOpen] = useState(false);

  return (
    <nav className="lg:h-[90px] md:h-[110px] h-[70px] fixed w-full bg-stawi-white shadow z-50 items-center flex justify-between lg:px-[50px] pr-0 pl-0">
      <button
        onClick={() => setOpen(!open)}
        className="lg:hidden visible lg:pl-0 pl-4 text-stawi-darkgreen"
      >
        {open ? <BiX size={30} /> : <BiMenu size={35} />}
      </button>

      <div className="lg:w-[160px] lg:static md:static absolute right-6 md:w-[150px] lg:mt-4 w-[70px]">
        <img src="stawilogo.png" alt="Stawi Foods and Fruits Logo" />
      </div>

      <nav className="bg-stawi-white block">
        <ul
          className={`lg:flex block lg:w-full w-[100%] lg:bg-stawi-white bg-stawi-white transition-all duration-700 lg:static absolute h-screen lg:justify-between lg:h-full ${
            open
              ? "top-0 left-0 lg:pt-0 pt-6 rounded"
              : "left-[-100%] md:left-[-100%] top-0 lg:opacity-100 right-0"
          }`}
        >
          {links.map((link) => (
            <li key={link.to} className="lg:text-left text-left lg:mt-0 mt-3">
              <NavLink
                to={link.to}
                onClick={() => setOpen(!open)}
                className="lg:text-sm md:text-[30px] text-[16px] ml-4 mr-4 text-stawi-black lg:text-stawi-darkgreen lg:px-0 lg:py-0 py-5  hover:text-stawi-green lg:font-poppins font-bold cursor-pointer"
              >
                {link.label}
              </NavLink>
            </li>
          ))}

          <li>
            <button
              onClick={() => setOpen(!open)}
              className="lg:hidden visible lg:pl-0 pl-4 text-stawi-darkgreen absolute top-2 right-2"
            >
              <BiX size={45} />
            </button>
          </li>

          <li>
            <div
              className={`lg:mr-0 visible lg:hidden md:hidden flex mt-6 lg:text-[14px] text-[10px] items-center justify-between px-4`}
            >
              <a href="tel:+254763466910" className="ml-2 ">
                <StawiButton
                  icon
                  buttonIcon={<BiPhoneCall size={24} />}
                  buttonStyle={
                    "bg-stawi-white hover:shadow-none shadow-lg text-stawi-darkgreen rounded-[4px]"
                  }
                  buttonText={"Call us"}
                  iconStyle={"px-2"}
                />
              </a>

              <a
                href="mailto:stawifoods@gmail.com?subject = Feedback&body = Message"
                className="ml-2"
              >
                <StawiButton
                  icon
                  buttonIcon={<BiMailSend size={24} />}
                  buttonText={"Email"}
                  buttonStyle={
                    "rounded-[4px] text-stawi-white bg-stawi-darkgreen hover:shadow-none shadow-lg"
                  }
                  iconStyle={"px-2"}
                />
              </a>
            </div>
          </li>
        </ul>
      </nav>
      {renderButtons()}
    </nav>
  );
};

export default StawiNavbar;
